export default class SystemConstant {
    public static protocol = 'https';
    public static ServerWeb = {
        host: 'localhost:11180',
    };
    public static TextExtraction = {
        host: "analytics.anyon.vn",
        API: {
            PostResultPreview: 'api/file/PdfResultOverView',
            ResultPreviewPage: 'api/file/PdfResultEachPage',
            ScanColumn: (pageNumber: number) => `api/NCAnalysis/ScanColumn/${pageNumber}`, //testing 
            ScanColumnPolyline: (pageNumber: number) => `api/NCAnalysis/ScanColumnPolyline/${pageNumber}`, //testing
            SearchText: (baseFileId: string, searchText: string) => `api/NCAnalysis/SearchText/${baseFileId}/${searchText}`,
            GetNumberAnalysisPage: `api/NCAnalysis/GetNumberAnalysisPage`,
            ApproveScanColumnResult: (pageNumber: number) => `api/NCAnalysis/ApproveScanColumnResult/${pageNumber}`,
            ColumnResultsEachPage: `api/NCAnalysis/ColumnResultsEachPage`,
            SearchCurrentSheet: (pageNumber: number) => `api/file/SearchCurrentSheet/${pageNumber}`,
            applyField: (pageIndex: number) => `api/file/ApplySearchCurrentSheet/${pageIndex}`,
            applyAllSheet: 'api/file/ApplySearchAllSheets',
            getFileInfo: (baseFileId: string) => `api/file/${baseFileId}`,
            downloadExcel: (baseFileId: string) => `api/file/DownloadExcel/${baseFileId}`,
            downloadExcelNCAnalysis: (baseFileId: string) => `api/NCAnalysis/DownloadExcel/${baseFileId}`,
            completeVerification: (baseFileId: string) => `api/file/CompleteVerify/${baseFileId}`,
            analytics: 'api/NCAnalysis/Analyse'
        }
    };
    public static Stream = {
        host: "3dviewer.anyon.vn/server",
        API: {
            DownloadFile: 'api/download'
        }
    };
    public static Conversion = {
        host: "3dviewer.anyon.vn/service/conversion",
        API: {
            CACHE: {
                GetFile: 'api/StreamFile/adeptfileid'
            }
        }
    };

    public static File = {
        host: 'analytics.anyon.vn/server/file',
    }

    public static NameTable = ["Vật liệu", "Kích thước", "Số lượng"];
    public static UndefinedNameTable = "Không xác định";
}
