import { FileInfo } from "common/define";
import SystemConstant from "./SystemConstant";

export default class FileApi {
    // static host = `${SystemConstant.protocol}://${SystemConstant.ServerWeb.host}`;
    static host = `${SystemConstant.protocol}://${SystemConstant.File.host}`;
    static getUrlStreamfile(file: FileInfo): string {
        const location = file.streamLocation.substr(file.streamLocation.lastIndexOf('\\') + 1);
        return `${this.host}/${location}/${file.filename}`;
    }
}